import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Visible } from '@trileuco/triskel-react-ui/components/helpers'
import {
  Button,
  PopOver,
  IconButton
} from '@trileuco/triskel-react-ui/components/ui'
import { useHistory } from '@trileuco/triskel-react-ui/components/router'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useModal } from '@trileuco/triskel-react-ui/components/ui/Modal'
import {
  useBookingPaymentAction,
  useBookingRemoveAction,
  useBookingPaymentWithWalletCard,
  useBookingPaymentGatewayPurchase,
  useGetBookable,
  useUpdateBookingDateTime,
  useUpdateBookingState
} from 'components/api'
import {
  bookingsPath,
  generateBookingPath,
  generateBookingPaymentPath
} from 'modules/bookables/routes'
import ToUpdateBookingTimePickerModal from 'components/booking/ToUpdateBookingTimePickerModal'
import ToUpdateBookingStateModal from 'components/booking/ToUpdateBookingStateModal'
import BookingInvoice from 'modules/bookables/pages/bookingDetails/summary/BookingInvoice'
import { useTenantConfig } from 'modules/bookables/Provider'
import { currentOTAS } from 'modules/bookables/domain/otas'

import { PaymentMethodAndStateModal } from './PaymentMethodAndStateModal/PaymentMethodAndModal'
import BookingPaymentGatewayForm from './BookingPaymentGatewayForm'

const paymentStatus = {
  CANCELED: 'canceled',
  PAID: 'paid',
  RESERVED: 'reserved',
  INVITED: 'invited',
  PENDING: 'pending'
}

const gateways = ['nmi', 'paycomet']

const BookingActions = ({ booking, payment, refetch, hidePayButton }) => {
  const { msg } = useIntl({
    scope: 'balaena.bookingActions',
    ignoreGlobalScope: true
  })
  const {
    bookable: { allowedStates = [] },
    states = { timeline: [] }
  } = booking

  const {
    defaultStates: { bookables: defaultBookableStates = [] } = {}
  } = useTenantConfig()

  const authContext = useAuth()
  const history = useHistory()
  const {
    mutate: paymentWithWalletCard,
    loading: loadingPaymentWithWalletCard
  } = useBookingPaymentWithWalletCard({
    bookingId: booking.id,
    reservationPayment: _.get(payment, 'reservation')
  })

  const {
    mutate: paymentGatewayPurchase,
    loading: loadingPaymentGatewayPurchase
  } = useBookingPaymentGatewayPurchase({
    bookingId: booking.id,
    reservationPayment: _.get(payment, 'reservation')
  })
  const { mutate: cancel, loading: loadingCancel } = useBookingPaymentAction({
    paymentAction: 'cancel',
    bookingId: booking.id
  })
  const { mutate: remove, loading: loadingRemove } = useBookingRemoveAction({
    bookingId: booking.id
  })

  const { bookable } = useGetBookable({
    bookableId: booking.bookable.id
  })

  const { updateDateTime } = useUpdateBookingDateTime(booking.id)

  const handleUpdateTime = useCallback(
    (dateParams) => {
      return updateDateTime(dateParams).then(refetch)
    },
    [updateDateTime, refetch]
  )

  const { updateState } = useUpdateBookingState(booking.id)

  const handleUpdateState = useCallback(
    (stateParams) => {
      return updateState(stateParams).then(() => {
        refetch({ bookingId: booking.id })
      })
    },
    [updateState, refetch, booking]
  )

  const isAdmin = authContext.hasSomeRole([
    'balaena-admin',
    'balaena-bookings-manager'
  ])

  const isRoot = authContext.hasSomeRole(['balaena-root'])

  const isOtaBooking = currentOTAS.includes(booking?.booker?.email)

  const isInvited = _.get(booking, 'payment.status') === paymentStatus.INVITED
  const isCancel =
    !booking.payment ||
    _.get(booking, 'payment.status') === paymentStatus.CANCELED
  const isPaid =
    _.get(booking, 'payment.status') === paymentStatus.PAID || isInvited
  const isPending = _.get(booking, 'payment.status') === paymentStatus.PENDING

  const cannotPay = loadingCancel || isCancel || isPaid
  const isChangeable = !isCancel
  const inPaymentPage = history.location.pathname.includes('payment')

  const emptyActionsMenuForManagers =
    isCancel && authContext.hasSomeRole(['balaena-bookings-manager'])

  const showBookingActionsMenu =
    !inPaymentPage &&
    !emptyActionsMenuForManagers &&
    (isPaid || isCancel || (isPending && isAdmin))

  const {
    isOpen: isOpenMarkAsPaid,
    open: openMarkAsPaid,
    close: closeMarkAsPaid
  } = useModal(false)

  const {
    open: openChangeDate,
    close: closeChangeDate,
    isOpen: changeDataIsOpen
  } = useModal(false)

  const {
    open: openUpdateState,
    close: closeUpdateState,
    isOpen: changeStateIsOpen
  } = useModal(false)

  const CancelButton = (props) => (
    <Button
      text={msg({ id: 'cancelBooking' })}
      onClick={() => {
        if (window.confirm(msg({ id: 'cancelBooking.confirm' }))) {
          cancel().then(() => {
            inPaymentPage ? history.push(bookingsPath) : refetch()
          })
        }
      }}
      loading={loadingCancel.toString()}
      {...props}
    />
  )
  CancelButton.propTypes = {
    booking: PropTypes.shape()
  }

  const RemoveButton = (props) => (
    <Button
      text={msg({ id: 'removeBooking' })}
      onClick={() => {
        if (window.confirm(msg({ id: 'removeBooking.confirm' }))) {
          remove().then(() => history.push(bookingsPath))
        }
      }}
      loading={loadingRemove.toString()}
      {...props}
    />
  )

  const ChangeDateButton = () => {
    return (
      <Button
        text={msg({ id: 'changeDate' })}
        onClick={openChangeDate}
        variant="clear"
      />
    )
  }

  const GoToPaymentButton = () => {
    return (
      <Button
        text={msg({ id: 'payments' })}
        linkTo={generateBookingPaymentPath({ id: booking.id })}
        variant="clear"
      />
    )
  }

  const UpdateBookingStateButton = () => {
    return (
      <Button
        text={msg({ id: 'changeState' })}
        onClick={openUpdateState}
        variant="clear"
      />
    )
  }

  const showRemoveBookingButton = useMemo(() => {
    return authContext.hasAllRoles(['balaena-admin'])
  }, [authContext])

  const BookingActionsMenu = () => (
    <PopOver
      alias="BookingActionsMenuTooltip"
      placement="bottom-end"
      content={
        <ul className="BookingActionsMenu">
          <Visible condition={!isPaid && !isCancel}>
            <li>
              <GoToPaymentButton />
            </li>
          </Visible>
          <Visible condition={isChangeable && isAdmin && !isOtaBooking}>
            <li>
              <ChangeDateButton />
            </li>
          </Visible>
          <Visible
            condition={!_.isEmpty(allowedStates) && !isCancel && isAdmin}
          >
            <li>
              <UpdateBookingStateButton />
            </li>
          </Visible>
          <Visible condition={!isCancel}>
            {!isInvited && (
              <li>
                <BookingInvoice bookingId={booking.id} />
              </li>
            )}
            <Visible condition={(isAdmin && !isOtaBooking) || isRoot}>
              <li>
                <CancelButton variant="clear" color="danger" />
              </li>
            </Visible>
          </Visible>
          <Visible
            condition={(showRemoveBookingButton && !isOtaBooking) || isRoot}
          >
            <li>
              <RemoveButton variant="clear" color="danger" />
            </li>
          </Visible>
        </ul>
      }
      triggerEvent="click"
    >
      <IconButton icon="fas fa-ellipsis-v" variant="clear" size="l" />
    </PopOver>
  )

  const stateTimeline = useMemo(() => {
    return states.timeline.map(({ state }) => state)
  }, [states])

  const stateOptions = useMemo(() => {
    const filteredStates =
      booking && booking.bookable.continuos
        ? _.difference(allowedStates, defaultBookableStates)
        : allowedStates

    return filteredStates.map((state) => ({
      value: state,
      label: state,
      disabled: stateTimeline.includes(state)
    }))
  }, [allowedStates, booking, defaultBookableStates, stateTimeline])

  return (
    <div className="BookingActions">
      <Visible
        condition={
          payment &&
          !isCancel &&
          !isPaid &&
          !_.get(payment, 'method') &&
          !hidePayButton
        }
      >
        <Button text={msg({ id: 'safePayment' })} disabled />
      </Visible>
      <Visible condition={_.get(payment, 'method') === 'wallet'}>
        <Visible
          condition={
            !isCancel &&
            !isPaid &&
            authContext.hasSomeRole(['balaena-agency', 'balaena-booker']) &&
            authContext.userInfo.email === booking.booker.email
          }
        >
          <Button
            text={msg({ id: 'safePayment' })}
            onClick={() =>
              paymentWithWalletCard(_.get(payment, 'wallet')).then(() =>
                history.push(generateBookingPath({ id: booking.id }))
              )
            }
            loading={loadingPaymentWithWalletCard}
            disabled={cannotPay || !_.get(payment, 'wallet')}
          />
        </Visible>
      </Visible>
      <Visible
        condition={
          _.get(payment, 'method') === 'gateway' &&
          !isCancel &&
          !isPaid &&
          authContext.hasSomeRole(['balaena-agency', 'balaena-booker']) &&
          authContext.userInfo.email === booking.booker.email
        }
      >
        <Visible condition={_.get(payment, 'gateway') === 'cecabank'}>
          <BookingPaymentGatewayForm
            booking={booking}
            reservationPayment={_.get(payment, 'reservation')}
          />
        </Visible>
        <Visible condition={gateways.includes(_.get(payment, 'gateway'))}>
          <Button
            text={msg({ id: 'safePayment' })}
            onClick={() =>
              paymentGatewayPurchase({
                paymentToken: _.get(payment, 'paymentGateway.token')
              }).then(() =>
                history.push(generateBookingPath({ id: booking.id }))
              )
            }
            loading={loadingPaymentGatewayPurchase}
            disabled={cannotPay || !_.get(payment, 'paymentGateway.token')}
          />
        </Visible>
      </Visible>
      <Visible condition={payment && !isCancel && !isPaid && isAdmin}>
        <Button
          variant="outline"
          text={msg({ id: 'markBookingAsPaid' })}
          onClick={openMarkAsPaid}
          disabled={cannotPay}
        />
      </Visible>
      <PaymentMethodAndStateModal
        bookingId={booking.id}
        isOpen={isOpenMarkAsPaid}
        close={closeMarkAsPaid}
        stateOptions={stateOptions}
      />
      {showBookingActionsMenu && (
        <>
          <BookingActionsMenu />
          <ToUpdateBookingTimePickerModal
            booking={booking}
            bookable={bookable}
            handleUpdateTime={handleUpdateTime}
            isOpen={changeDataIsOpen}
            close={closeChangeDate}
          />
          <ToUpdateBookingStateModal
            handleUpdateState={handleUpdateState}
            stateOptions={stateOptions}
            isOpen={changeStateIsOpen}
            close={closeUpdateState}
          />
        </>
      )}
      <Visible condition={inPaymentPage && !isCancel && isAdmin}>
        <CancelButton color="danger" variant="outline" />
      </Visible>
      <Visible condition={inPaymentPage && showRemoveBookingButton}>
        <RemoveButton color="danger" variant="fill" />
      </Visible>
    </div>
  )
}

BookingActions.propTypes = {
  booking: PropTypes.shape(),
  payment: PropTypes.shape({
    method: PropTypes.string,
    reservation: PropTypes.bool,
    wallet: PropTypes.object
  }),
  refetch: PropTypes.func,
  hidePayButton: PropTypes.bool
}

BookingActions.displayName = 'BookingActions'

export default BookingActions
